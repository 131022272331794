import React, { useState } from "react";
import Footer from "../../components/common/Footer";
import HeaderHome from "./components/HeaderHome";
import MainHome from "./components/MainHome";
import MenuHome from "./components/MenuHome";
import RouteModal from "./components/RouteModal/RouteModal";
import ServiceModal from "./components/ServiceModal";

export default function Home() {
  const [showServiceModal, setShowServiceModal] = useState(false);
  const [showRouteModal, setShowRouteModal] = useState(false);
  return (
    <>
      <HeaderHome />
      <MenuHome />
      <MainHome
        setShowServiceModal={setShowServiceModal}
        setShowRouteModal={setShowRouteModal}
      />
      <Footer />
      <ServiceModal show={showServiceModal} setShow={setShowServiceModal} />
      <RouteModal show={showRouteModal} setShow={setShowRouteModal} />
    </>
  );
}
