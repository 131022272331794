import { SET_DATE_TICKETS, NO_SELECTED_DATE } from "../actions/dates";

export const initialState = {
  selected: false,
  date: {
    id: null,
    cupos: null,
  },
};

export const datesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATE_TICKETS:
      return {
        ...state,
        selected: true,
        date: action.payload,
      };

    case NO_SELECTED_DATE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
