import { useParams, useHistory } from "react-router-dom";
import "./styles.css";
export default function Feedback() {
  const { compra } = useParams();
  const history = useHistory();

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div
        className={`${
          compra === "done" ? "img-compra-exitosa" : "img-compra-rechazada"
        }`}
      ></div>
      <p>
        {compra === "done"
          ? "Se le enviara un mail con el detalle de la compra registrada."
          : "No se pudo registrar su compra. Intente nuevamente o comuniquese al 0336 4445555"}
      </p>
      <button className="btn-volver" onClick={() => history.push("/")}>
        Volver
      </button>
    </div>
  );
}
