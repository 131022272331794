import React from "react";
import { Modal } from "react-bootstrap";
import recorridoModal from "../../../../assets/img/recorrido-modal.png";
import "./styles.css";

export default function RouteModal({ show, setShow }) {
  const handleClose = () => {
    setShow(false);
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Recorrido</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <a href={recorridoModal} target="_blank">
          <img src={recorridoModal} width="100%" />
        </a>
      </Modal.Body>
    </Modal>
  );
}
