import React from "react";
import { useHistory } from "react-router-dom";
import "./styles.css";
import video from "../../../../assets/videos/videosn.mp4";

window.addEventListener("load", async () => {
  let video = document.getElementById("videosn");
  try {
    await video.play();
  } catch (err) {
    console.log(err);
  }
});

export default function HeaderHome() {
  const history = useHistory();
  return (
    <div className="d-flex flex-column">
      <div style={{ height: "400px", overflow: "hidden" }}>
        <video
          id="videosn"
          width="100%"
          height="100%"
          playsInline
          autoPlay
          muted
          loop
          className="video"
        >
          <source src={video} type="video/mp4" />
        </video>
      </div>

      <div className="header-buy">
        <button
          className="header-buy-btn"
          onClick={() => history.push("/ticket")}
        >
          Comprar tickets
        </button>
      </div>
    </div>
  );
}
