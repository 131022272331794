import React, { useContext } from "react";
import { Table } from "react-bootstrap";
import { TicketContext } from "../../../../context/TicketContext";
import UserTicket from "../UserTicket/UserTicket";
import Ticket from "../Ticket";
import Cupon from "../Cupon/Cupon";

export default function Tickets() {
  const tContext = useContext(TicketContext);
  const {
    addTicket,
    subsctractTicketQuantity,
    tickets,
    total,
    arrayTicketsIds,
    totalTickets,
    addClientTicket,
  } = tContext;

  return (
    <div>
      <Table striped bordered responsive className="container mb-5">
        <thead>
          <tr>
            <th>Tickets</th>
            <th className="text-center">Cantidad</th>
            <th className="text-center">Precio</th>
          </tr>
        </thead>
        <tbody>
          {tickets.length > 0 &&
            tickets.map((ticket) => (
              <Ticket
                key={ticket.Id_Ticket}
                description={ticket.Descripcion}
                price={ticket.Precio}
                id={ticket.Id_Ticket}
                quantity={ticket.cantidad}
                addTicketQuantity={addTicket}
                subsctractTicketQuantity={subsctractTicketQuantity}
              />
            ))}
          <tr>
            <td></td>
            <td className="text-center">Total</td>
            <td className="text-center">$ {total}</td>
          </tr>
        </tbody>
      </Table>
      <Cupon />
      {totalTickets === 0 ? null : (
        <p className="text-center mt-5">
          <strong>
            Ingresá la informacion de los pasajeros de los tickets
          </strong>
        </p>
      )}
      {arrayTicketsIds.map((id, index) => (
        <UserTicket
          key={index}
          index={index}
          id={id}
          addClient={addClientTicket}
          totalTickets={totalTickets}
        />
      ))}
    </div>
  );
}
