import { createContext, useReducer } from "react";
import { setDateTickets, noSelectedDate } from "../actions/dates";
import { datesReducer, initialState } from "../reducers/dates";

export const DateContext = createContext();

const { Provider } = DateContext;

export const DateContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(datesReducer, initialState);

  const setDate = (date) => {
    dispatch(setDateTickets({ date }));
  };

  const noSelected = () => dispatch(noSelectedDate());

  return (
    <Provider value={{ ...state, setDate, noSelected }}>{children}</Provider>
  );
};
