import React from "react";
import { Modal } from "react-bootstrap";

export default function ServiceModal({ show, setShow }) {
  const handleClose = () => {
    setShow(false);
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Descripción del servicio</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Los sábados, domingos y feriados recorré San Nicolás de una manera
          distinta.
        </p>
        <p>Horarios de partida: 11.30 y 18.00 hs.</p>
        <p>
          Subite al bus turístico en la emblemática plaza Mitre y pasá por los
          puntos más importantes. Vas a conocer parte de la historia, recorrer
          los espacios más visitados y disfrutar de las mejores vistas de una
          ciudad para descubrir.
        </p>
        <p>
          El bus tiene capacidad para 24 pasajeros, incluido un espacio para
          personas con discapacidad. Su recorrido geolocalizado te permitirá
          escuchar los audios informativos a medida que avances en el recorrido.
          Además, podrás optar por idioma español, inglés o portugués.
        </p>
        <p>
          Si estás interesado en visitas grupales o contingentes escribinos a{" "}
          <a href="mailto:info@descubrisannicolas.com.a">
            info@descubrisannicolas.com.ar
          </a>
        </p>
      </Modal.Body>
    </Modal>
  );
}
