import shortid from "shortid";
import {
  SET_TICKETS,
  ADD_QUANTITY,
  SUBSTRACT_QUANTITY,
  SET_CLIENTS,
  APPLY_CUPON,
} from "../actions/tickets";

export const initialState = {
  tickets: [],
  total: 0,
  totalTickets: 0,
  clients: [],
  arrayTicketsIds: [],
  dateTickets: {
    id: null,
    cupos: null,
  },
  cupon: {
    aplicado: false,
    codigo: "",
    descripcion: "",
    descuento: null,
  },
};

const calculateTotal = (tickets) =>
  tickets
    .map((ticket) => ticket.Precio * ticket.cantidad)
    .reduce((prev, curr) => prev + curr, 0);

const calculateTotalTickets = (tickets) =>
  tickets
    .map((ticket) => ticket.cantidad)
    .reduce((prev, curr) => prev + curr, 0);

const calculateDiscount = (percent, total) => {
  if (total === 0) return 0;
  const discount = ((percent / 100) * total).toFixed(2);
  return total - discount;
};

export const ticketsReducer = (state, action) => {
  switch (action.type) {
    case SET_TICKETS: {
      return {
        ...state,
        tickets: action.payload.tickets,
        total: calculateTotal(state.tickets),
      };
    }

    case ADD_QUANTITY: {
      const tickets = state.tickets.map((ticket) =>
        ticket.Id_Ticket === action.payload.id
          ? { ...ticket, cantidad: ticket.cantidad + 1 }
          : ticket
      );

      return {
        ...state,
        tickets,
        total: state.cupon.aplicado
          ? calculateDiscount(state.cupon.descuento, calculateTotal(tickets))
          : calculateTotal(tickets),
        totalTickets: calculateTotalTickets(tickets),
        arrayTicketsIds: [...state.arrayTicketsIds, shortid.generate()],
      };
    }

    case SUBSTRACT_QUANTITY: {
      const tickets = state.tickets.map((ticket) =>
        ticket.Id_Ticket === action.payload.id
          ? { ...ticket, cantidad: ticket.cantidad - 1 }
          : ticket
      );

      return {
        ...state,
        tickets,
        total: state.cupon.aplicado
          ? calculateDiscount(state.cupon.descuento, calculateTotal(tickets))
          : calculateTotal(tickets),
        totalTickets: calculateTotalTickets(tickets),
        arrayTicketsIds: state.arrayTicketsIds.filter(
          (_, index) => index < state.arrayTicketsIds.length - 1
        ),
        clients: state.clients.filter(
          (_, index) => index < state.clients.length - 1
        ),
      };
    }

    case SET_CLIENTS: {
      const { client } = action.payload;
      const foundClient = state.clients.find(
        (currentClient) => currentClient.id === client.id
      );

      if (foundClient) {
        const clients = state.clients.map((currentClient) =>
          currentClient.id === foundClient.id
            ? { ...currentClient, dni: client.dni, nombre: client.nombre }
            : currentClient
        );

        return {
          ...state,
          clients,
        };
      }
      return {
        ...state,
        clients: [...state.clients, action.payload.client],
      };
    }

    case APPLY_CUPON:
      return {
        ...state,
        cupon: {
          aplicado: true,
          codigo: action.payload.cupon.Codigo,
          descripcion: action.payload.cupon.Descripcion,
          descuento: action.payload.cupon.Porcentaje_Descuento,
        },
        total: calculateDiscount(
          action.payload.cupon.Porcentaje_Descuento,
          state.total
        ),
      };

    default:
      return state;
  }
};
