import React from "react";

import "./styles.css";

export default function MainHome({ setShowServiceModal, setShowRouteModal }) {
  return (
    <div className="main-container">
      <div className="main-home">
        <div className="main-service" onClick={() => setShowServiceModal(true)}>
          <span className="main-text">Descripción del servicio</span>
        </div>
        <div className="main-route" onClick={() => setShowRouteModal(true)}>
          <span className="main-text">Recorrido</span>
        </div>
      </div>
    </div>
  );
}
