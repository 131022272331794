import React from "react";
import { useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import dayjs from "dayjs";
import { useFetch } from "../../hooks/useFetch";

export default function Purchase() {
  const { id } = useParams();
  const [data, loading, error] = useFetch(`/compra/detalle/${id}`);

  if (loading) return <h1>Cargando...</h1>;
  if (error) return <h1>Hubo un error!</h1>;

  return (
    <div className="container my-3">
      <h2 className="text-center">Pasajeros</h2>
      <Table
        striped
        bordered
        responsive
        className="shadow p-3 mb-5 mt-2 bg-white rounded"
      >
        <thead>
          <tr>
            <td>Nombre y Apellido</td>
            <td>DNI / Pasaporte</td>
          </tr>
        </thead>
        <tbody>
          {data?.personasDetalles.length > 0 &&
            data?.personasDetalles.map(([persona]) => (
              <tr key={persona.Id_Persona}>
                <td>{persona.Nombre_Apellido}</td>
                <td>{persona.DNI_Pasaporte}</td>
              </tr>
            ))}
        </tbody>
      </Table>
      <h2 className="text-center">Viaje</h2>
      <Table
        striped
        bordered
        responsive
        className="shadow p-3 mb-5 mt-2 bg-white rounded"
      >
        <thead>
          <tr>
            <td>Fecha</td>
            <td>Hora</td>
            <td>Viaje</td>
            <td>Recorrido</td>
          </tr>
        </thead>
        <tbody>
          {data?.viaje?.Id_Viaje && (
            <tr>
              <td>{dayjs(data?.viaje.Fecha).format("DD/MM/YYYY")}</td>
              <td>{data?.viaje.Hora}</td>
              <td>{data?.viaje.Tipo_Viaje_Descripcion}</td>
              <td>{data?.viaje.Recorrido_Descripcion}</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
}
