import * as yup from "yup";

export const validationSchema = yup.object().shape({
  nombre: yup.string().required(),
  dni: yup
    .number()
    .typeError("Sólo se permiten números")
    .required("Este campo es obligatorio"),
});

export const validationPhone = yup.object().shape({
  phone: yup
    .number()
    .typeError("Sólo se permiten números")
    .required("Este campo es obligatorio"),
});
