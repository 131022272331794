import React, { useState } from "react";
import { validationSchema } from "../../../../utils/validations";

const UserTicket = ({ addClient, id, index }) => {
  const [nombre, setNombre] = useState("");
  const [dni, setDni] = useState("");
  const [error, setError] = useState(false);

  const addNewClient = async () => {
    const valid = await validationSchema.isValid({
      nombre,
      dni,
    });
    if (valid) {
      setError(false);
      const client = {
        nombre,
        dni,
        id,
      };
      addClient(client);
    } else {
      setError(true);
    }
  };

  return (
    <div className="container mb-5 px-0">
      <div className="email-container" onBlur={addNewClient}>
        <div>
          <form className="w-100">
            <div className="email-inputs-container">
              <label>
                <strong>Ticket:{index + 1}</strong>
              </label>
              <input
                placeholder="Nombre y Apellido"
                className="email"
                type="text"
                onChange={(e) => setNombre(e.target.value)}
                value={nombre}
              />
              <input
                placeholder="DNI"
                className="email"
                type="text"
                onChange={(e) => setDni(e.target.value)}
                value={dni}
              />
            </div>
          </form>
        </div>
        {error && (
          <div className="error">
            {" "}
            Por favor verifique los siguientes datos:
            <br />- Los campos son obligatorios
            <br />- El DNI debe ser númerico
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(UserTicket);
