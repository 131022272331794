import React from "react";
import { Row } from "react-bootstrap";

export default class TyCToPrint extends React.PureComponent {
  render() {
    return (
      <div>
        <Row className="px-3">
          <p>
            <strong>Norma vigente</strong>
          </p>
          <p>
            La adquisición de tickets por medio electrónico en el sitio
            busturistico.descubrisannicolas.com.ar y su utilización implican la
            aceptación de todas las condiciones anteriormente expuestas y de las
            normas vigentes en materia de transporte de pasajeros.
          </p>
          <p>Control del comprobante / Acreditación de identidad</p>
          <p>
            El usuario es responsable de ingresar datos veraces al momento de la
            compra. Todo dato falso ingresado por el usuario será completa
            responsabilidad del mismo.
          </p>
        </Row>
        <Row className="px-3">
          <p>
            <strong>Comprobante de la compra</strong>
          </p>
          <p>
            Las compras realizadas a través de esta web son realizadas por
            consumidores finales, sirviendo los pasajes como comprobante
            suficiente de tales transacciones.
          </p>
        </Row>
        <Row className="px-3 flex-column">
          <p>
            <strong>Devolución del Ticket</strong>
          </p>
          <p>Los tickets no tienen cambio ni devolución.</p>
        </Row>
        <Row className="px-3 flex-column">
          <p>
            <strong>Menores</strong>
          </p>
          <p>
            Los niños de hasta 4 (cuatro) años, inclusive, viajan gratis. Es
            obligatorio que viajen acompañados de un mayor de edad.
          </p>
        </Row>
        <Row className="px-3">
          <p>
            <strong>Documentación</strong>
          </p>
          <p>
            El pasajero tiene la obligación de satisfacer todas las exigencias
            gubernamentales en materia de documentos personales exigidos para
            viajar. La municipalidad transportadora no se responsabiliza por los
            gastos e inconvenientes ocasionados por el incumplimiento por parte
            del pasajero de esas exigencias.
          </p>
        </Row>
        <Row className="px-3">
          <p>
            <strong>Seguro</strong>
          </p>
          <p>
            Los pasajes adquiridos incluyen un seguro de responsabilidad hacia
            pasajeros transportados de acuerdo con disposiciones legales
            vigentes.
          </p>
        </Row>

        <Row className="px-3 flex-column">
          <p>
            <strong>Restricciones</strong>
          </p>
          <p>Está totalmente prohibido fumar dentro de los vehículos.</p>
          <p>
            Por razones de seguridad, no pueden transportarse elementos que de
            algún modo pongan en peligro la seguridad del vehículo,
            pasajerostransportados ni al personal de la municipalidad
            Transportadora.
          </p>
          <p>
            Ningún pasajero podrá permanecer parado mientras el vehículo se
            encuentre en movimiento.
          </p>
          <p>
            Ningún pasajero podrá consumir bebidas alcohólicas en el interior de
            los vehículos, sin distinción de edades ni de horarios.
          </p>
          <p>
            Los pasajeros deberán observar las reglas mínimas de decoro y
            respeto tanto dentro de las unidades como con el mobiliario interno
            de las mismas, con los pasajeros que lo rodean, con los transeúntes,
            otros vehículos, casas, edificios y mobiliario urbano de la ciudad.
          </p>
          <p>
            Ningún pasajero podrá dañar las instalaciones de los vehículos bajo
            apercibimiento de ser descendido del mismo.
          </p>
          <p>
            Ningún pasajero podrá ascender a los vehículos con el torso desnudo.
          </p>
          <p>
            La municipalidad no es responsable por los gastos o perjuicios
            provocados por la demora en salida, llegada o interrupciones durante
            el viaje, cuando ello se deba a casos fortuitos o de fuerza mayor.
          </p>
          <p>
            La municipalidad reserva el derecho de admisión y permanencia del
            pasajero incluso el inicio de acciones legales ante cualquier motivo
            que constituya peligro o molestia para los pasajeros o personal de
            la municipalidad.
          </p>
        </Row>
      </div>
    );
  }
}
