import React, { useRef } from "react";
import { Modal, Row } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import TyCToPrint from "../TyCToPrint";
import "./styles.css";

export default function ModalTyC({ show, close }) {
  const componentRef = useRef();
  const handleClose = () => {
    close();
  };
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>CONDICIONES GENERALES</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TyCToPrint ref={componentRef} />
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Row>
          <ReactToPrint
            trigger={() => <a className="btn-print">Imprimir</a>}
            content={() => componentRef.current}
          />
        </Row>
      </Modal.Footer>
    </Modal>
  );
}
