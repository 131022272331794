import './styles.css';

export default function PurchaseSteps() {
	return (
		<div className='pasos'>
			<div className='cont'>
				<div className='col3 right-gap'>
					<span>1</span>
					<p>Elegí el ticket</p>
				</div>
				<div className='col3 right-gap'>
					<span className='line'>2</span>
					<p>Completá los datos</p>
				</div>
				<div className='col3'>
					<span>3</span>
					<p>Detalles de la compra</p>
				</div>
			</div>
		</div>
	);
}
