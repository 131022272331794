import React, { useEffect, useState } from "react";
import DateTicket from "./components/DateTicket";
import PurchaseSteps from "./components/PurchaseSteps";
import Tickets from "./components/Tickets";
import Footer from "../../components/common/Footer";
import EmailPurchase from "./components/EmailPurchase";
import HeaderTicket from "./components/HeaderTicket";
import ModalTyC from "./components/ModalTyC";
import { DateContextProvider } from "../../context/DateContext";
import { ReducedMobilityModal } from "./components/ReducedMobilityModal/ReducedMobilityModal";
import { TicketContextProvider } from "../../context/TicketContext";

export default function Ticket() {
  const [showModalTyC, setShowModalTyC] = useState(false);
  const [showReducedMobilityModal, setShowReducedMobilityModal] =
    useState(false);

  useEffect(() => {
    setShowReducedMobilityModal(true);
  }, []);

  const openModalTyC = () => {
    setShowModalTyC(true);
  };

  const closeModalTyc = () => {
    setShowModalTyC(false);
  };

  return (
    <div>
      <TicketContextProvider>
        <ReducedMobilityModal
          showReducedMobilityModal={showReducedMobilityModal}
        />
        <HeaderTicket />
        <DateContextProvider>
          <DateTicket />
          <PurchaseSteps />
          <Tickets />
          <EmailPurchase openModalTyC={openModalTyC} />
        </DateContextProvider>
        <ModalTyC show={showModalTyC} close={closeModalTyc} />
        <Footer />
      </TicketContextProvider>
    </div>
  );
}
