import React from "react";
import "./styles.css";

export default function Footer() {
  return (
    <div className="footer">
      <p className="text-center">
        Rivadavia 51, B2900LPA San Nicolás de los Arroyos
        <br />
        <a href="mailto:info@descubrisannicolas.com.ar">
          info@descubrisannicolas.com.ar
        </a>
      </p>
    </div>
  );
}
