import { useState, useEffect } from "react";
import { API } from "../API";

export const useFetch = (url) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const getData = async (url) => {
    try {
      const { data } = await API.get(url);

      setData(data);
    } catch (e) {
      console.error(e);
      setError(true);
    }
    setLoading(false);
  };
  useEffect(() => {
    getData(url);
  }, [url]);
  return [data, loading, error];
};
