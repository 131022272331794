import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

const reducedMobilityEmail = "info@descubrisannicolas.com.ar";

export function ReducedMobilityModal({ showReducedMobilityModal }) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (showReducedMobilityModal) return handleShow(true);
  }, [showReducedMobilityModal]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Importante</Modal.Title>
      </Modal.Header>
      <Modal.Body className="my-3">
        {`Contamos con asiento para pasajeros con movilidad reducida. Reservá contactándote a ${reducedMobilityEmail}`}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleClose}>Entendido</Button>
      </Modal.Footer>
    </Modal>
  );
}
