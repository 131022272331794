import { createContext, useEffect, useReducer } from "react";
import {
  addTicketQuantity,
  setTickets,
  subsctractTicket,
  addClient,
  applyCupon,
} from "../actions/tickets";
import { ticketsReducer, initialState } from "../reducers/tickets";
import { useFetch } from "../hooks/useFetch";

export const TicketContext = createContext();

const { Provider } = TicketContext;

export const TicketContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ticketsReducer, initialState);
  const [data, loading, error] = useFetch("/tickets");

  const addTicket = (id) => {
    dispatch(addTicketQuantity(id));
  };

  const subsctractTicketQuantity = (id) => {
    dispatch(subsctractTicket(id));
  };

  const addClientTicket = (client) => {
    dispatch(addClient(client));
  };

  const setApplyCupon = (cupon) => {
    dispatch(applyCupon(cupon));
  };

  useEffect(() => {
    if (!loading && data.tickets) {
      dispatch(
        setTickets(data.tickets.map((ticket) => ({ ...ticket, cantidad: 0 })))
      );
    }
  }, [loading, data]);

  return (
    <Provider
      value={{
        addTicket,
        subsctractTicketQuantity,
        addClientTicket,
        setApplyCupon,
        ...state,
      }}
    >
      {children}
    </Provider>
  );
};
