import React from "react";

import menu1 from "../../../../assets/img/menu-1.png";
import menu2 from "../../../../assets/img/menu-2.png";
import menu3 from "../../../../assets/img/menu-3.png";
import menu4 from "../../../../assets/img/menu-4.png";
import menu5 from "../../../../assets/img/menu-5.png";
import "./styles.css";

export default function MenuHome() {
  return (
    <div className="menuhome-container">
      <div className="menuhome-menu-container">
        <img className="menuhome-menu" src={menu1} alt="menu1" />
      </div>
      <div className="menuhome-menu-container">
        <img className="menuhome-menu" src={menu2} alt="menu2" />
      </div>
      <div className="menuhome-menu-container">
        <img className="menuhome-menu" src={menu3} alt="menu3" />
      </div>
      <div className="menuhome-menu-container">
        <img className="menuhome-menu" src={menu4} alt="menu4" />
      </div>
      <div className="menuhome-menu-container">
        <img className="menuhome-menu" src={menu5} alt="menu5" />
      </div>
    </div>
  );
}
