import React from "react";

export default function Ticket({
  description,
  price,
  quantity,
  addTicketQuantity,
  subsctractTicketQuantity,
  id,
}) {
  return (
    <tr>
      <td>{description}</td>
      <td className="text-center">
        <div className="d-flex justify-content-center align-items-center">
          <button
            className="btn mr-3"
            style={{ backgroundColor: "#4e89c7", color: "white" }}
            onClick={() =>
              quantity === 0 ? null : subsctractTicketQuantity(id)
            }
          >
            -
          </button>
          <span>{quantity}</span>
          <button
            className="btn ml-3"
            style={{ backgroundColor: "#4e89c7", color: "white" }}
            onClick={() => addTicketQuantity(id)}
          >
            +
          </button>
        </div>
      </td>
      <td className="text-center">$ {price}</td>
    </tr>
  );
}
