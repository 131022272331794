export const SET_TICKETS = "SET_TICKETS";
export const ADD_QUANTITY = "ADD_QUANTITY";
export const SUBSTRACT_QUANTITY = "SUBSTRACT_QUANTITY";
export const SET_CLIENTS = "SET_CLIENTS";
export const APPLY_CUPON = "APPLY_CUPON";

export const addTicketQuantity = (id) => ({
  type: ADD_QUANTITY,
  payload: { id },
});

export const setTickets = (tickets) => ({
  type: SET_TICKETS,
  payload: { tickets },
});

export const subsctractTicket = (id) => ({
  type: SUBSTRACT_QUANTITY,
  payload: { id },
});

export const addClient = (client) => ({
  type: SET_CLIENTS,
  payload: { client },
});

export const applyCupon = (cupon) => ({
  type: APPLY_CUPON,
  payload: { cupon },
});
