import React, { useState, useEffect, useContext } from "react";
import { useFetch } from "../../../../hooks/useFetch";
import { DateContext } from "../../../../context/DateContext";
import "./styles.css";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es"; // the locale you want
import "react-datepicker/dist/react-datepicker.css";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import addMonths from "date-fns/addMonths";
import { API } from "../../../../API";

export default function DateTicket() {
  registerLocale("es", es); // register it with the name you want
  const [data, loading, error] = useFetch("/viajes");
  const [optionsDates, setOptionsDates] = useState([]);
  const dContext = useContext(DateContext);
  const { setDate, noSelected } = dContext;
  const [startDate, setStartDate] = useState(null);

  // const handleDates = (e) => {
  //   if (e.target.value === "no-selected") {
  //     return noSelected();
  //   }

  //   const dateSelected = optionsDates.filter(
  //     (date) => date.Id_Viaje == e.target.value
  //   );

  //   setDate({
  //     id: dateSelected[0].Id_Viaje,
  //     cupos: dateSelected[0].Cant_Pasajeros,
  //   });
  // };

  useEffect(() => {
    if (!loading && data?.viajes.length) {
      const travelOptions = data.viajes;
      const firstChoiceAvailable = travelOptions[0];
      const date = new Date(firstChoiceAvailable.Fecha);
      const time = firstChoiceAvailable.Hora;
      const hour = time.split(":");

      date.setHours(hour[0], hour[1]);

      setStartDate(date);
      calculateHours(date);
      setOptionsDates(travelOptions);
    }
  }, [data, loading]);

  const highlightWithRanges = optionsDates.map((date) => {
    const fechaViajes = new Date(date.Fecha);
    return fechaViajes;
  });
  const [hoursByDate, setHoursByDate] = useState([]);

  const [cupos, setCupos] = useState(0);

  let highlightWithRangesHours = [];

  const calculateHours = async (date) => {
    const selectedDate = date.toISOString().slice(0, 10);
    const hour = date.toLocaleTimeString("default", {
      hour: "2-digit",
      minute: "2-digit",
    });

    // const hour =
    // 	(new Date(date).getHours() < 10 ? "0" : "") + new Date(date).getHours();
    // const minutes =
    // 	(new Date(date).getMinutes() < 10 ? "0" : "") +
    // 	new Date(date).getMinutes();
    // const totalTime = hour + ":" + minutes;

    const respuesta = await API.get(`/viajes/horas/?date=${selectedDate}`);

    const respuestaCupos = await API.get("/viajes/cupos/", {
      params: {
        fecha: selectedDate,
        hora: hour,
      },
    });
    setCupos(respuestaCupos?.data?.infoCupos[0]?.Cant_Pasajeros);
    highlightWithRangesHours = respuesta.data.infoViajes.map((date) => {
      const hora = Number(date.Hora.substring(0, 2));
      const horaViajes = setHours(setMinutes(new Date(date.Fecha), 0), hora);
      const min = Number(date.Hora.substring(3));
      horaViajes.setMinutes(min);
      return horaViajes;
    });
    setHoursByDate(highlightWithRangesHours);
    setDate({
      fecha: selectedDate,
      hora: hour,
    });
  };

  return (
    <div
      className="d-flex p-3 justify-content-around"
      style={{ backgroundColor: "#107bb1" }}
    >
      <div className="d-flex align-items-center flex-column flex-md-row">
        <span className="nd-label">Fecha</span>

        <DatePicker
          locale="es"
          timeFormat="p"
          dateFormat="Pp"
          selected={startDate}
          onChange={(date) => {
            setStartDate(date);
            calculateHours(date);
          }}
          minDate={new Date()}
          maxDate={addMonths(new Date(), 5)}
          includeDates={highlightWithRanges}
          showTimeSelect
          includeTimes={hoursByDate}
          timeCaption="Hora"
          className="text-center"
        />
        <span className="nd-label">
          Cupos Disponibles: {cupos > 0 ? cupos : " No hay Cupos"}
        </span>
      </div>
    </div>
  );
}
