import React, { useState, useContext } from "react";
import Swal from "sweetalert2";
import { TicketContext } from "../../../../context/TicketContext";
import { DateContext } from "../../../../context/DateContext";
import { validationPhone } from "../../../../utils/validations";
import { API } from "../../../../API";
import "./styles.css";

export default function EmailPurchase({ openModalTyC }) {
  const [email, setEmail] = useState("");
  const [confEmail, setConfEmail] = useState("");
  const [acceptTyC, setAcceptTyC] = useState(false);
  const [error, setError] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorCupos, setErrorCupos] = useState(false);
  const [phone, setPhone] = useState("");
  const [errorPhone, setErrorPhone] = useState(false);

  const tContext = useContext(TicketContext);
  const dContext = useContext(DateContext);
  const { tickets, clients, totalTickets, cupon } = tContext;
  const { date, selected } = dContext;

  const handleErrorPhone = async () => {
    const valid = await validationPhone.isValid({
      phone,
    });
    if (valid) {
      setErrorPhone(false);
    } else {
      setErrorPhone(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      email === "" ||
      confEmail === "" ||
      !acceptTyC ||
      clients.length === 0 ||
      !selected ||
      !phone
    ) {
      setError(true);
      return;
    }
    setError(false);
    if (email !== confEmail) {
      setErrorEmail(true);
      return;
    }
    if (date.date.cupos < totalTickets) {
      setErrorCupos(true);
      return;
    }

    var newTickets = tickets.filter(function (el) {
      return el.cantidad > 0;
    });

    if (!date.date.fecha || !date.date.hora) {
      setError(true);
      return;
    }

    setError(false);

    setErrorEmail(false);
    setErrorCupos(false);

    const data = {
      email,
      newTickets,
      clients,
      fecha: date.date.fecha,
      hora: date.date.hora,
      cantPasajeros: totalTickets,
      codigo: cupon.codigo,
      phone,
    };

    try {
      Swal.fire({
        title: "<strong>¿Desea confirmar su compra?</strong>",
        icon: "question",
        html: `<div class="mb-2"><i class="fa fa-calendar fa-lg" aria-hidden="true"></i> <b>Fecha de viaje: ${
          data.fecha
        } a las ${data.hora} hs.</b></div>
        <div class="mb-2"><i class="fa fa-ticket fa-lg" aria-hidden="true"></i> <b>Tickets:</b> <ul>${data.newTickets.map(
          (ticket) =>
            `${ticket.Descripcion} $${ticket.Precio} Cantidad: ${ticket.cantidad}`
        )}</ul></div>
        <div class="mb-2"><i class="fa fa-user fa-lg" aria-hidden="true"></i> <b>Pasajeros:</b> <ul>${data.clients.map(
          (client) => `Nombre: ${client.nombre} Dni: ${client.dni}`
        )}</ul></div>
        <div class="mb-2"><i class="fa fa-envelope-o fa-lg" aria-hidden="true"></i> <b>Correo:</b> <ul>${
          data.email
        }</ul></div>
        <div><i class="fa fa-phone" aria-hidden="true"></i> <b>Teléfono:</b> <ul>${
          data.phone
        }</ul></div>`,
        // showCloseButton: true,
        showCancelButton: true,
        cancelButtonColor: "#ee2823",
        focusConfirm: false,
        confirmButtonText: "Confirmar",
        confirmButtonColor: "#4e89c7",
        cancelButtonText: "Cancelar",
        width: 600,
        reverseButtons: true,
        iconColor: "#4e89c7",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const respuesta = await API.post("/compra", data);
          window.location.href = respuesta.data.init_point;
        }
      });
    } catch (error) {
      Swal.fire("Error", error.response.data.msg, "error").then(() =>
        window.location.reload()
      );
    }
  };
  return (
    <div className="container my-5 px-0">
      <div className="email-container">
        <p className="text-center">
          <strong>Ingresá el email donde recibirás los tickets</strong>
        </p>
        <div>
          <form className="w-100" onSubmit={handleSubmit}>
            <div className="email-inputs-container">
              <input
                placeholder="Email"
                className="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                placeholder="Confirmar email"
                className="email"
                type="email"
                value={confEmail}
                onChange={(e) => setConfEmail(e.target.value)}
              />
            </div>
            <p className="text-center mt-5">
              <strong>Ingresá un teléfono de contacto</strong>
            </p>
            <div className="email-inputs-container" onBlur={handleErrorPhone}>
              <input
                placeholder="Teléfono"
                className="email"
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            {errorPhone && (
              <div className="error"> Sólo se permiten números</div>
            )}
            <div className="d-flex justify-content-center align-items-center mt-5">
              <input
                type="checkbox"
                style={{ width: "20px", height: "20px" }}
                value={acceptTyC}
                onChange={() => setAcceptTyC(!acceptTyC)}
              />
              <span className="ml-3 tyc-link" onClick={() => openModalTyC()}>
                He leído y acepto los Términos y Condiciones
              </span>
            </div>
            {error && (
              <div className="error">
                Por favor verifique los siguientes datos:
                <br />- Complete la fecha de reserva de los tickets
                <br />- Ingrese el email donde enviaremos los tickets.
                <br />- Ingrese el email confirmando donde enviaremos los
                tickets.
                <br />- Ingrese un número de teléfono de contacto.
                <br />- Debe aceptar los Términos y Condiciones.
                <br />- No ha seleccionado ningún ticket
                <br />
              </div>
            )}
            {errorEmail && (
              <div className="error">
                {" "}
                Por favor verifique los siguientes datos:
                <br />
                Las direcciones de Email no coinciden
              </div>
            )}
            {errorCupos && (
              <div className="error">
                {" "}
                Por favor verifique los siguientes datos:
                <br />
                La cantidad de asientos disponibles es insuficiente
              </div>
            )}
            <div>
              <button className="btn-buy" type="submit">
                Comprar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
