import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import Swal from "sweetalert2";
import { API } from "../../../../API";
import { TicketContext } from "../../../../context/TicketContext";
import "./styles.css";

export default function Cupon() {
  const tContext = useContext(TicketContext);
  const { setApplyCupon, cupon } = tContext;
  const [codigo, setCodigo] = useState("");

  const handleCodigo = (e) => setCodigo(e.target.value);

  const handleValidation = async () => {
    if (cupon.aplicado) return;
    try {
      const { data } = await API.post("/cupon/validar", { codigo });

      setApplyCupon(data.cupon[0]);
      Swal.fire("Exito!", "Se aplicó el cupón correctamente!", "success");
    } catch (error) {
      console.log(error.response.data.msg);
      Swal.fire("Error!", error.response.data.msg, "error");
    }
  };
  return (
    <div>
      <div className="d-flex justify-content-center align-items-center">
        <p className="mb-0 mr-2">
          <b>Cupón de descuento</b>
        </p>
        <div className="d-flex align-items-center">
          <input type="text" className="cupon" onChange={handleCodigo} />
          <button className="btn btn-validar ml-2" onClick={handleValidation}>
            Validar
          </button>
          {cupon?.aplicado && (
            <FontAwesomeIcon
              icon={faCheckCircle}
              size="2x"
              color="#28a745"
              className="ml-2"
            />
          )}
        </div>
      </div>
      {cupon?.aplicado && (
        <p className="text-center mt-1">
          <b>{cupon.descripcion}</b>
        </p>
      )}
    </div>
  );
}
