import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
} from "react-router-dom";
import Feedback from "../pages/Feedback";
import Home from "../pages/Home";
import Purchase from "../pages/Purchase";
import Ticket from "../pages/Ticket";

export default function AppRouter() {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/ticket" component={Ticket} />
          <Route exact path="/compra/:id" component={Purchase} />
          <Route exact path="/feedback/:compra" component={Feedback} />
          <Redirect to="/" />
        </Switch>
      </div>
    </Router>
  );
}
